import React, { useEffect } from 'react'
import GlobalStyles from 'styles/GlobalStyles'
import { css } from 'styled-components/macro' //eslint-disable-line

import TermsOfServicePage from 'pages/TermsOfService.js'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SaaSProductLandingPage from './demos/SaaSProductLandingPage'
import SEO from './SEO'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ReactGA from 'react-ga4'

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  useEffect(() => {
    ReactGA.initialize('G-1R5LBG0WHX')
    ReactGA.send({
      hitType: 'pageview',
      page: '/home',
      title: 'Home View',
    })
  })

  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path='/' element={<SaaSProductLandingPage />} />
          <Route path='/terms-of-service' element={<TermsOfServicePage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  )
}
