import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import iOSImageSource from "images/ios.png";
import androidImageSource from "images/android.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import SEO from "../SEO";

const basePlanFeatures = [
  "Push Notifications",
  "Pull To Refresh",
  "Downloads & Uploads",
  "App Linking & Sharing",
  "Media Playback",
  "24/7 Support",
];

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      {/* <div id="#features">
        <Features
          subheading={<Subheading>Features</Subheading>}
          heading={
            <>
              We have Amazing <HighlightedText>Service.</HighlightedText>
            </>
          }
          description=""
        />
      </div> */}
      {/* <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        description={"And we have got answers to all of them."}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is Sketch AI  ?",
            answer:
              "Sketch AI   is an artificial intelligence-powered tool designed to help with essay writing.",
          },
          {
            question: "How does Sketch AI   work?",
            answer:
              "Sketch AI   uses machine learning algorithms to generate high-quality content based on your input.",
          },
          {
            question:
              "Can Sketch AI   help with any type of essay?",
            answer:
              "Yes, Sketch AI   can help with a variety of essay types, including research papers, argumentative essays, and more.",
          },
          {
            question: "How can I get started with Sketch AI  ?",
            answer:
              "Simply sign up for an account and start inputting your essay prompts and requirements.",
          },
          {
            question:
              "Can I edit the content generated by Sketch AI  ?",
            answer:
              "Yes, you can make any necessary edits or revisions to the content generated by Sketch AI  .",
          },
          {
            question:
              "Is my personal information secure with Sketch AI  ?",
            answer:
              "Yes, Sketch AI   takes the privacy and security of your personal information very seriously.",
          },
          {
            question:
              "Can I get support if I have questions or issues with Sketch AI  ?",
            answer:
              "Yes, our customer support team is available to help you with any questions or issues you may have.",
          },
        ]}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
